import React from 'react';
import {Form} from "react-bootstrap";

const ManagerFormField = ({value, handler, caption, id, isRequired}) => {
    return (
        <Form.Group className="custom-form__group mb-6" controlId={id}>
            <Form.Label className="custom-form__label">{caption}</Form.Label>
            <Form.Control
                type="text"
                required={isRequired}
                className="custom-form__input"
                value={value}
                onChange={handler}
            />
            {isRequired && (
                <Form.Control.Feedback className="custom-form__error" type="invalid">Обязательное поле</Form.Control.Feedback>
            )}
        </Form.Group>
    );
};

export default ManagerFormField;
