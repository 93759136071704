import React, {useState, useEffect} from 'react';
import {observer} from "mobx-react";
import {videoItems} from "../data/video";
import WindowsStore from "../stores/WindowsStore";

const Videos = observer(() => {
    const [videos, setVideo] = useState(videoItems);
    const [videoNumber, setVideoNumber] = useState(1);
    const menuState = WindowsStore.menuState;

    useEffect(() => {
        const currentVideo = videos.find((obj) => obj.class === '');
        const videoObj = videos[currentVideo.id].ref.current;
        if (menuState) videoObj.pause(); else videoObj.play();
    }, [menuState, videos]);


    const playVideo = () => {
        const playPromise = videos[videoNumber].ref.current.play();

        if (playPromise !== undefined) {
            playPromise.then(_ => {
                videos[videoNumber].ref.current.play();
            })
            .catch(error => {});
        }
    }

    const pauseVideo = () => {
        videos[videoNumber].ref.current.pause();
    }

    const nextVideo = () => {
        pauseVideo();

        setVideoNumber(videoNumber + 1);
        if (videoNumber >= videos.length - 1) {
            setVideoNumber(0);
        }

        showVideo();
        playVideo();
        videos[videoNumber].ref.current.currentTime = 0;
    }

    const showVideo = () => {
        setVideo(videos.map((v) => {
            v.id === videoNumber ? v.class = '' : v.class = 'background__video-hidden';
            return v;
        }));
    };

    return (
        <div className="background__video-wrapper">
            {videos.map((video) => (
                <video
                    ref={video.ref}
                    id={`video-background-${video.id}`}
                    key={video.id}
                    preload="auto"
                    autoPlay={video.class === ''}
                    muted
                    onEnded={nextVideo}
                    className={`background__video ${video.class}`}
                >
                    <source src={video.path} type="video/mp4"/>
                </video>
            ))}
        </div>
    );
});

export default Videos;