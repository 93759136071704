export const feedbackItems = [
    ...Object(Array(21).keys())
].map((e) => {
    return `./docs/feedback/feedback_${e+1}.jpg`;
});

export const sertItems = [
    ...Object(Array(7).keys())
].map((e) => {
    return `./docs/serts/sert_${e+1}.jpg`;
});

export const patentItems = [
    ...Object(Array(7).keys())
].map((e) => {
    return `./docs/patents/patent_${e+1}.jpg`;
});