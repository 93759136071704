import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageViewer = ({items}) => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplaySpeed: 3000,
        fade: true,
        autoplay: true,
        pauseOnHover: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div className="image-viewer">
            <div className='image-viewer__wrapper'>
                <Slider {...settings}>
                    {items.map((e, i) => (
                        <img key={i} src={e} alt="Изображение"/>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default ImageViewer;
