import React from 'react';
import Videos from "./Videos";

const BackgroundVideoWrapper = () => {
    return (
        <div className="background__wrapper">
            <div className="background__top">
                <div className="logo"></div>
            </div>
            <Videos/>
            <div className="background__bottom">
                <div className="logo"></div>
            </div>
        </div>
    );
};

export default BackgroundVideoWrapper;