import axios from "axios";
import ConfigStore from "../stores/ConfigStore";

export const sendListEmails = async (data) => {
    let remoteReq = false;

    const localReq = await sendEmailRequest (
    'http://localhost:4000/save-emails',
    {data: data}
    );

    if (!ifLocalhost()) {
        remoteReq = await sendEmailRequest (
            'http://auth.dev-02.waviot.ru/?action=email-emailFromStand',
            {
                access_key: ConfigStore.emailAccessKey,
                email_to: ConfigStore.emailTo,
                title: ConfigStore.emailTitle,
                data: data
            }
        );
    }


    return localReq?.data?.code === 200 || remoteReq?.data?.code === 200;
}

const sendEmailRequest = async (url, data) => {
    const config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
        }
    };

    return await axios.post(url, data, config)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return false;
        });
}

const ifLocalhost = () => {
    return window.location.href.includes('localhost')
}