import React, {useEffect, useState} from 'react';
import FormStore from "../stores/FormStore";
import {Button, Form} from "react-bootstrap";
import ConfigStore from "../stores/ConfigStore";
import Table from 'react-bootstrap/Table';
import {observer} from "mobx-react";
import {sendListEmails} from "../utils/handler";
import {ToastContainer, toast} from 'react-toastify';
import ManagerFormField from "../components/ManagerFormField";

//TODO доделать
const Manager = observer (() => {
    const [form, setForm] = useState({
        baseUrl: ConfigStore.baseStoreUrl,
        emailTo: ConfigStore.emailTo,
        customLinkLk: ConfigStore.customLinks?.lk,
        customLinkUSPD: ConfigStore.customLinks?.uspd,
        customLinkPiramida: ConfigStore.customLinks?.piramida,
        customLinkMain: ConfigStore.customLinks?.main,
    });
    const [validated, setValidated] = useState(false);
    let database = FormStore.database;

    useEffect(() => {
        window.addEventListener("storage", onStorageUpdate);
    })

    const onStorageUpdate = () => {
        database = FormStore.database;
    }

    const submitData = (e) => {
        e.preventDefault();

        if (e.currentTarget.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            ConfigStore.setUrl(form.baseUrl);
            ConfigStore.setEmailTo(form.emailTo);
            ConfigStore.setCustomLinks({
                lk: form.customLinkLk,
                uspd: form.customLinkUSPD,
                piramida: form.customLinkPiramida,
                main: form.customLinkMain
            });
            ConfigStore.updateConfig();
            toast.success("Успешно сохранено!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }

        setValidated(true);
    }

    const handleChange = (e) => {
        setForm({...form, [e.target.id]: e.target.value});
    };

    const clearArchive = () => {
        FormStore.clear();
    }

    const resendEmails = async () => {
        FormStore.get();
        const result = await sendListEmails(FormStore.database);

        if (result) {
            FormStore.replace(FormStore.database.map((e) => {
                e.is_new = false;
                return e;
            }));
            FormStore.save();
            toast.success(`Успешно отправлено ${FormStore.database?.length}`, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        } else {
            toast.error("Ошибка отправки!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    }

    return (
        <div className="manager__wrapper">
            <h2 className="manager__title">Архив обратной связи<small className="text-primary"> / {FormStore.database?.length} чел.</small></h2>
            <div className="manager__archive-wrapper">
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>№</th>
                        <th>ФИО</th>
                        <th>Почта</th>
                        <th>Телефон</th>
                        <th>Статус</th>
                    </tr>
                    </thead>
                    <tbody>
                    {database.map((e, i) => (
                        <tr key={i}>
                            <td>{i}</td>
                            <td>{e.fio}</td>
                            <td>{e.email ?? '-'}</td>
                            <td>{e.phone ?? '-'}</td>
                            <td>{e.is_new
                                ? <div className="badge bg-danger">NEW</div>
                                : <div className="badge bg-success">SENT</div>
                            }</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
            <div className="row">
                <div className="col-sm-6">
                    <Button className="custom-form__btn" disabled onClick={clearArchive} variant="danger" type="submit">
                        Очистить
                    </Button>
                </div>
                <div className="col-sm-6">
                    <Button className="custom-form__btn" onClick={resendEmails} variant="primary" type="submit">
                        Переотправить всё
                    </Button>
                </div>
            </div>

            <br/>

            <h2 className="manager__title">Настройки</h2>
            <Form noValidate validated={validated} onSubmit={submitData} className={`custom-form`}>

                <ManagerFormField
                    id="baseUrl"
                    caption="Путь к хранилищу *"
                    value={form.baseUrl}
                    handler={handleChange}
                    isRequired
                />

                <ManagerFormField
                    id="emailTo"
                    caption="Email для отправки почты *"
                    value={form.emailTo}
                    handler={handleChange}
                    isRequired
                />

                <ManagerFormField
                    id="customLinkLk"
                    caption="Ссылка на личный кабинет *"
                    value={form.customLinkLk}
                    handler={handleChange}
                    isRequired
                />

                <ManagerFormField
                    id="customLinkUSPD"
                    caption="Ссылка на личный кабинет УСПД *"
                    value={form.customLinkUSPD}
                    handler={handleChange}
                    isRequired
                />

                <ManagerFormField
                    id="customLinkPiramida"
                    caption="Ссылка на личный кабинет Piramida *"
                    value={form.customLinkPiramida}
                    handler={handleChange}
                    isRequired
                />

                <ManagerFormField
                    id="customLinkMain"
                    caption="Ссылка на сайт *"
                    value={form.customLinkMain}
                    handler={handleChange}
                    isRequired
                />

                <Button className="custom-form__btn" variant="primary" type="submit">
                    Сохранить
                </Button>
            </Form>
            <ToastContainer />
        </div>
    );
});

export default Manager;
