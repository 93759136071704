import React from 'react';
import ConfigStore from "../stores/ConfigStore";

const PdfReader = ({url}) => {
    return (
        <div className="pdf__wrapper">
            <embed src={`${ConfigStore.baseStoreUrl + url}#view=FitH`} width="100%" height="100%" />
        </div>
    );
};

export default PdfReader;
