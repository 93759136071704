import { makeAutoObservable } from "mobx"

class ConfigStore {
    baseStoreUrl = 'http://localhost:4000/storage/';
    emailTo = 'natterum@gmail.com';
    resendTimeout = 120 * 1000;
    emailAccessKey = "6605979b92ed6e668799b783d8132033";
    emailTitle = "Зарегистрировались новые пользователи";
    customLinks = {
        lk: 'https://lk.waviot.ru/',
        uspd: '',
        piramida: 'http://10.168.20.184:5000/management',
        main: 'https://waviot.ru/'
    };

    constructor() {
        makeAutoObservable(this);
        this.getConfig ();
    }

    setUrl (val) {
        this.baseStoreUrl = val;
    }

    setEmailTo (val) {
        this.emailTo = val;
    }

    setCustomLinks (val) {
        this.customLinks = val;
    }

    updateConfig () {
        localStorage.exhibitionConfig = JSON.stringify({
            baseStoreUrl: this.baseStoreUrl,
            emailTo: this.emailTo,
            customLinks: this.customLinks
        })
    }

    getConfig () {
        console.log(localStorage.exhibitionConfig)
        if (localStorage?.exhibitionConfig) {
            const params = JSON.parse(localStorage.exhibitionConfig);

            this.baseStoreUrl = params?.baseStoreUrl;
            this.emailTo = params?.emailTo;

            ['uspd', 'lk', 'piramida', 'main'].map((e) => {
                this.customLinks[e] = params?.customLinks?.[e];
                return e;
            })
        }
    }
}

export default new ConfigStore();