import React, {useEffect} from 'react';
import Menu from "../components/Menu";
import BackgroundVideoWrapper from "../components/BackgroundVideoWrapper";
import {sendListEmails} from "../utils/handler";
import FormStore from "../stores/FormStore";
import ConfigStore from "../stores/ConfigStore";
import CommonStore from "../stores/CommonStore";

const Home = () => {

    useEffect(() => {
        emailSenderTimerStart();
    })

    const emailSenderTimerStart = () => {

        if (!CommonStore.emailSenderTimer) {
            CommonStore.setEmailSenderTimer(setTimeout (emailSender, ConfigStore.resendTimeout));
        }
    }

    const emailSender = async () => {
        FormStore.get();
        const data = FormStore.database.filter((obj) => obj.is_new);

        if (data?.length) {
            const result = await sendListEmails(data);
            if (result) {
                FormStore.replace(FormStore.database.map((e) => {
                    e.is_new = false;
                    return e;
                }));
                FormStore.save();
                console.log (`Email sent ${data?.length}`);
            } else {
                console.log ('Error sending emails');
            }
        }

        CommonStore.setEmailSenderTimer (null);
        emailSenderTimerStart();
    }

    return (
        <div>
            <style>{"body {overflow: hidden;}"}</style>
            <Menu/>
            <BackgroundVideoWrapper/>
        </div>
    );
};

export default Home;