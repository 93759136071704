import Website from '../components/Website';
import PdfReader from '../components/PdfReader';
import VideoPlayer from '../components/VideoPlayer';
import RegistrationForm from '../components/FeedbackForm';
import ImageViewer from "../components/ImageViewer";
import {feedbackItems, sertItems, patentItems} from "./images";
import ConfigStore from "../stores/ConfigStore";

console.log (ConfigStore.customLinks)

export const menuItems = [
    //Main menu
    {
        parent: null,
        type: 'data',
        caption: 'личный кабинет',
        icon: require('../assets/images/icons/ico_lk.svg').default,
        data: () => <Website newtab url={ConfigStore.customLinks?.lk}/>,
    },
    {
        parent: null,
        type: 'data',
        caption: 'личный кабинет УСПД',
        icon: require('../assets/images/icons/ico_cloud.svg').default,
        data: () => <Website newtab url={ConfigStore.customLinks?.uspd}/>,
    },
    {
        parent: null,
        type: 'data',
        caption: 'пирамида сети',
        icon: require('../assets/images/icons/ico_piramida.svg').default,
        data: () => <Website newtab url={ConfigStore.customLinks?.piramida}/>,
    },
    {
        parent: null,
        type: 'data',
        caption: 'сайт',
        icon: require('../assets/images/icons/ico_logo_fav.svg').default,
        data: () => <Website url={ConfigStore.customLinks?.main}/>,
    },
    {
        id: 20,
        parent: null,
        type: 'menu',
        caption: 'продукция',
        icon: require('../assets/images/icons/ico_electric.svg').default,
        data: () => {},
    },
    {
        id: 4,
        parent: null,
        type: 'menu',
        caption: 'видео',
        icon: require('../assets/images/icons/ico_play.svg').default,
    },
    {
        parent: null,
        type: 'data',
        caption: 'регистрация',
        icon: require('../assets/images/icons/ico_registration.svg').default,
        data: () => <RegistrationForm />,
    },
    {
        id: 2,
        parent: null,
        type: 'menu',
        caption: 'о компании',
        icon: require('../assets/images/icons/ico_alt_ico.svg').default,
    },

    //Меню: о компании
    {
        parent: 2,
        type: 'data',
        caption: 'презентация о компании',
        icon: require('../assets/images/icons/ico_presentation.svg').default,
        data: () => <PdfReader url='./pdf/about_company.pdf'/>,
    },
    {
        parent: 2,
        type: 'data',
        caption: 'сертификаты и патенты',
        icon: require('../assets/images/icons/ico_certs.svg').default,
        data: () => <ImageViewer items={[...sertItems, ...patentItems]}/>,
    },
    {
        parent: 2,
        type: 'data',
        caption: 'отзывы',
        icon: require('../assets/images/icons/ico_feedback.svg').default,
        data: () => <ImageViewer items={feedbackItems}/>,
    },

    //Меню: Листовки
    {
        parent: 17,
        type: 'data',
        caption: 'счетчик электрической энергии ФОБОС 1',
        icon: require('../assets/images/icons/ico_doc_electric.svg').default,
        data: () => <PdfReader url='./pdf/lists/el_fobos_1.pdf'/>,
    },
    {
        parent: 17,
        type: 'data',
        caption: 'счетчик электрической энергии ФОБОС 1 сплит',
        icon: require('../assets/images/icons/ico_doc_electric.svg').default,
        data: () => <PdfReader url='./pdf/lists/el_fobos_1_split.pdf'/>,
    },
    {
        parent: 17,
        type: 'data',
        caption: 'счетчик электрической энергии ФОБОС 3',
        icon: require('../assets/images/icons/ico_doc_electric.svg').default,
        data: () => <PdfReader url='./pdf/lists/el_fobos_3.pdf'/>,
    },
    {
        parent: 17,
        type: 'data',
        caption: 'счетчик электрической энергии ФОБОС 3 сплит',
        icon: require('../assets/images/icons/ico_doc_electric.svg').default,
        data: () => <PdfReader url='./pdf/lists/el_fobos_3_split.pdf'/>,
    },
    {
        parent: 17,
        type: 'data',
        caption: 'счетчик электрической энергии ФОБОС 3Т',
        icon: require('../assets/images/icons/ico_doc_electric.svg').default,
        data: () => <PdfReader url='./pdf/lists/el_fobos_3t.pdf'/>,
    },
    {
        parent: 17,
        type: 'data',
        caption: 'счетчик электрической энергии ФОБОС 3 и 3 Т',
        icon: require('../assets/images/icons/ico_doc_electric.svg').default,
        data: () => <PdfReader url='./pdf/lists/el_fobos_3_3t.pdf'/>,
    },
    {
        parent: 17,
        type: 'data',
        caption: 'счетчик воды<br/> АКВА',
        icon: require('../assets/images/icons/ico_doc_electric.svg').default,
        data: () => <PdfReader url='./pdf/lists/counter_aqua.pdf'/>,
    },
    {
        parent: 17,
        type: 'data',
        caption: 'счетчик газа ГАЗ УЛЬТРА',
        icon: require('../assets/images/icons/ico_doc_electric.svg').default,
        data: () => <PdfReader url='./pdf/lists/gas_ultra.pdf'/>,
    },
    {
        parent: 17,
        type: 'data',
        caption: 'счетчик тепла ГЕФЕСТ',
        icon: require('../assets/images/icons/ico_doc_electric.svg').default,
        data: () => <PdfReader url='./pdf/lists/warm_gefest.pdf'/>,
    },
    {
        parent: 17,
        type: 'data',
        caption: 'УСПД',
        icon: require('../assets/images/icons/ico_doc_electric.svg').default,
        data: () => <PdfReader url='./pdf/lists/uspd.pdf'/>,
    },

    //Меню: Видео обзор приборов
    {
        parent: 30,
        type: 'data',
        caption: 'ФОБОС 1',
        icon: require('../assets/images/icons/ico_play.svg').default,
        data: () => <VideoPlayer src='video/fobos_1.mp4' caption='Обзор ФОБОС 1'/>,
    },
    {
        parent: 30,
        type: 'data',
        caption: 'ФОБОС 1 сплит',
        icon: require('../assets/images/icons/ico_play.svg').default,
        data: () => <VideoPlayer src='video/fobos_1_split.mp4' caption='Обзор ФОБОС 1 сплит'/>,
    },
    {
        parent: 30,
        type: 'data',
        caption: 'ФОБОС 3',
        icon: require('../assets/images/icons/ico_play.svg').default,
        data: () => <VideoPlayer src='video/fobos_3.mp4' caption='Обзор ФОБОС 3'/>,
    },
    {
        parent: 30,
        type: 'data',
        caption: 'ФОБОС 3 сплит',
        icon: require('../assets/images/icons/ico_play.svg').default,
        data: () => <VideoPlayer src='video/fobos_3_split.mp4' caption='Обзор ФОБОС 3 сплит'/>,
    },

    //Меню продукция
    {
        parent: 20,
        type: 'data',
        caption: 'каталог',
        icon: require('../assets/images/icons/ico_multidoc.svg').default,
        data: () => <PdfReader url='./pdf/catalog.pdf'/>,
    },
    {
        id: 17,
        parent: 20,
        type: 'menu',
        caption: 'листовки',
        icon: require('../assets/images/icons/ico_doc_electric.svg').default,
    },
    {
        parent: 20,
        type: 'data',
        caption: 'презентация о продуктах',
        icon: require('../assets/images/icons/ico_monitor.svg').default,
        data: () => <PdfReader url='./pdf/equipment.pdf'/>,
    },
    {
        parent: 20,
        type: 'data',
        caption: 'проекты',
        icon: require('../assets/images/icons/ico_bulp.svg').default,
        data: () => <PdfReader url='./pdf/projects.pdf'/>,
    },

    //Меню: видео
    {
        parent: 4,
        type: 'data',
        caption: 'видео о компании',
        icon: require('../assets/images/logo.svg').default,
        data: () => <VideoPlayer src='video/about_company.mp4' caption='Видео о компании'/>,
    },
    {
        id: 40,
        parent: 4,
        type: 'data',
        caption: '3D видеоролик',
        icon: require('../assets/images/icons/ico_scheme.svg').default,
        data: () => <VideoPlayer src='video/3d_video.mp4' caption='3D видеоролик'/>,
    },
    {
        id: 30,
        parent: 4,
        type: 'menu',
        caption: 'видеообзоры приборов',
        icon: require('../assets/images/icons/ico_el_shield.svg').default,
    },

];