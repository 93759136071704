import React, {useState, useRef, useEffect} from 'react';
import {CSSTransition} from 'react-transition-group';
import {menuItems} from '../data/menu';
import MenuItem from "./MenuItem";
import '../assets/style/transition.scss'
import Modal from "./Modal";
import WindowsStore from '../stores/WindowsStore';
import {observer} from "mobx-react";

const Menu = observer(() => {
    const nodeRefMenuBack = useRef(null);
    const menuState = WindowsStore.menuState;
    const modalState = WindowsStore.modalState;

    const [activeMenu, setActiveMenu] = useState({current: null, parent: null});
    const [inMenuAnim, setinMenuAnim] = useState(false);
    const [modalContent, setModalContent] = useState(false);

    const menuClickHandler = async (current, parent) => {
        setinMenuAnim(false);
        await new Promise((r) => setTimeout(r, 100));
        setActiveMenu({current: current, parent: parent});
        setinMenuAnim(true);
    }

    const startMenuTimer = () => {
        clearTimeout(WindowsStore.menuTimer);

        if (!WindowsStore.modalState && WindowsStore.menuState) {
            WindowsStore.menuTimer = setTimeout (() => {
                WindowsStore.showMenu (false);
            }, 120 * 1000);
        }
    }

    const openModal = (e) => {
        e.type === 'menu' ? menuClickHandler(e?.id, e.parent) : WindowsStore.showModal(true);
        if (e.type === 'data') {
            setModalContent(e.data());
        }
    }

    useEffect(() => {
        startMenuTimer ()
    }, [menuState, modalState]);


    return (
        <div>
            <div className={`menu ${WindowsStore.menuState ? 'menu__show' : 'menu__hide'}`}>
                <div className="menu__wrapper">
                    <div className="menu__wrapper-inner">
                        <div className="menu-top__wrapper">
                            <div className="logo" onClick={() => WindowsStore.showMenu (false)}></div>
                            <div className="menu-top__subtitle">
                                с будущим на одной волне
                            </div>
                            <CSSTransition
                                timeout={300}
                                in={inMenuAnim}
                                nodeRef={nodeRefMenuBack}
                                classNames="menu-anim"
                            >
                                <ul className="menu-list__wrapper" ref={nodeRefMenuBack}>
                                    {activeMenu.current !== null ?
                                        <li
                                            className="menu-list__item"
                                            onClick={() => menuClickHandler(activeMenu.parent, null)}
                                        >
                                            <MenuItem
                                                count={menuItems.filter((f) => {return f.parent === activeMenu.current}).length}
                                                icon={require('../assets/images/icons/ico_back.svg').default}
                                                caption={'Назад'}
                                            />
                                        </li> : null
                                    }
                                    {menuItems.filter((f) => {return f.parent === activeMenu.current}).map ((e, i) =>
                                        <li className="menu-list__item"
                                            key={i}
                                            onClick={() => openModal (e)}
                                        >
                                            <MenuItem
                                                count={menuItems.filter((f) => {return f.parent === activeMenu.current}).length}
                                                icon={e.icon}
                                                caption={e.caption}
                                            />
                                        </li>
                                    )}
                                </ul>
                            </CSSTransition>
                        </div>
                    </div>
                </div>
            </div>
            <Modal content={modalContent}/>
            <div className="menu__field" onClick={() => WindowsStore.showMenu(true)}></div>
            <div className="screen-animation"></div>
            <div className="screen-animation screen-animation__two"></div>
            <div className="screen-animation screen-animation__three"></div>
        </div>
    );
});

export default Menu;