import { makeAutoObservable } from "mobx"

class WindowsStore {
    menuState = false;
    modalState = false;
    menuTimer = null;
    modalTimer = null;

    constructor() {
        makeAutoObservable(this);
    }

    showMenu (state) {
        this.menuState = state;
    }

    showModal (state) {
        this.modalState = state;
    }
}

export default new WindowsStore();