import React, {useRef, useState} from 'react';
import {Button, Form} from "react-bootstrap";
import {observer} from "mobx-react";
import WindowsStore from '../stores/WindowsStore';
import FormStore from "../stores/FormStore";
import {CSSTransition} from 'react-transition-group';
import moment from 'moment';

const RegistrationForm = observer(() => {
    const nodeRefForm = useRef(null);
    const nodeRefAlert = useRef(null);

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState(false);
    const fields = {
        fio: '',
        company: '',
        post: '',
        email: '',
        phone: '',
        question: '',
        is_new: true,
        date_created: '',
    }
    const [form, setForm] = useState(fields);

    const handleChange = (e) => {
        setForm({
            ...form, [e.target.id]: e.target.value,
            date_created: moment().format("DD.MM.YYYY HH:mm:ss")
        });
    };

    const submitData = (e) => {
        e.preventDefault();

        if (e.currentTarget.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            FormStore.get();
            FormStore.add(form)
            setForm(fields);
            setAlert (true);
            setTimeout(showForm, 10 * 1000);
        }

        setValidated(true);
    }

    const showForm = () => {
        setAlert(false);
    }

    return (
        <div className="custom-form__wrapper">
            <div className="custom-form__container">
                <div onClick={() => WindowsStore.showModal(false)}>
                    <div className="logo custom-form__logo"></div>
                    <div className="menu-top__subtitle custom-form__logo-subtitle">
                        Форма обратной связи
                    </div>
                </div>
                <CSSTransition
                    timeout={300}
                    in={!alert}
                    nodeRef={nodeRefForm}
                    classNames="menu-anim"
                >
                    <Form noValidate validated={validated} ref={nodeRefForm} onSubmit={submitData} className={`custom-form`}>
                        <Form.Group className="custom-form__group mb-6" controlId="fio">
                            <Form.Label className="custom-form__label">ФИО *</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                className="custom-form__input"
                                placeholder="Введите ФИО"
                                value={form.fio}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback className="custom-form__error" type="invalid">Обязательное поле</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="custom-form__group mb-6" controlId="company">
                            <Form.Label className="custom-form__label">Компания *</Form.Label>
                            <Form.Control
                                className="custom-form__input"
                                required
                                placeholder="Введите название вашей компании"
                                value={form.company}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback className="custom-form__error" type="invalid">Обязательное поле</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="custom-form__group mb-6" controlId="post">
                            <Form.Label className="custom-form__label">Должность</Form.Label>
                            <Form.Control
                                className="custom-form__input"
                                placeholder="Введите вашу должность"
                                value={form.post}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="custom-form__group mb-6" controlId="email">
                            <Form.Label className="custom-form__label">E-mail {!form.phone ? '*' : ''}</Form.Label>
                            <Form.Control
                                className="custom-form__input"
                                placeholder="Введите ваш email"
                                value={form.email}
                                onChange={handleChange}
                                required={!form.phone}
                            />
                            <Form.Control.Feedback className="custom-form__error" type="invalid">Заполните поле Email или Телефн</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="custom-form__group mb-6" controlId="phone">
                            <Form.Label className="custom-form__label">Телефон {!form.email ? '*' : ''}</Form.Label>
                            <Form.Control
                                required={!form.email}
                                className="custom-form__input"
                                placeholder="Введите ваш номер телефона"
                                value={form.phone}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback className="custom-form__error" type="invalid">Заполните поле Email или Телефн</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="custom-form__group mb-6" controlId="question">
                            <Form.Label className="custom-form__label">Ваш вопрос</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows="3"
                                className="custom-form__text"
                                placeholder="Введите ваш вопрос"
                                value={form.question}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback className="custom-form__error" type="invalid">Обязательное поле</Form.Control.Feedback>
                        </Form.Group>

                        <Button className="custom-form__btn btn-danger" variant="primary" type="submit">
                            ОТПРАВИТЬ
                        </Button>
                    </Form>
                </CSSTransition>
                <CSSTransition
                    timeout={300}
                    in={alert}
                    nodeRef={nodeRefAlert}
                    classNames="menu-anim"
                >
                    <div className={`custom-form-alert__wrapper`} ref={nodeRefAlert}>
                        <div className="custom-form-alert__icon"></div>
                        <div className="custom-form-alert__text">
                            <h2><b>Спасибо!</b></h2>
                            <p>Ваши контактые данные успешно отправлены в компанию Waviot.
                                Приятного дня!</p>
                        </div>
                    </div>
                </CSSTransition>
            </div>
        </div>
    );
});

export default RegistrationForm;
