import React, {useEffect} from 'react';
import WindowsStore from "../stores/WindowsStore";

const Website = ({url, newtab}) => {
    useEffect(() => {
        if (newtab && WindowsStore.modalState) {
            WindowsStore.showModal(false);
            window.open(url, '_blank');
        }
    });

    return (
        <div>
            {!newtab
                && <iframe
                    title="iframe"
                    className="website-frame"
                    src={url}
                >
                </iframe>
            }
        </div>
    );
};

export default Website;
