import {createRef} from "react";
import ConfigStore from "../stores/ConfigStore";

export const videoItems = [
    {
        id: 0,
        ref: createRef(),
        path: ConfigStore.baseStoreUrl + 'video/about_company.mp4',
        class: ''
    },
    {
        id: 1,
        ref: createRef(),
        path: ConfigStore.baseStoreUrl +'video/about_company.mp4',
        class: 'background__video-hidden'
    },
];