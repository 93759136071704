import React from 'react';

const MenuItem = (props) => {
    let classNames = require('classnames');

    return (
        <div className={classNames("menu-list__item-wrapper", props.count > 9 ? "menu-list__item-wrapper--compact" : "")}>
            <img
                className="menu-list__item-img"
                src={props.icon}
                alt="img"
            />
            <div className="menu-list__item-caption">
                <span dangerouslySetInnerHTML={{ __html: props.caption }} />
            </div>
        </div>
    );
};

export default MenuItem;