import { makeAutoObservable } from "mobx"

class FormStore {
    database = [];

    constructor() {
        this.get ();
        makeAutoObservable(this);
    }

    get () {
        try {
            this.database = JSON.parse(localStorage.feedbackDatabase);
        } catch (e) {}
    }

    save () {
        localStorage.feedbackDatabase = JSON.stringify(this.database);
    }

    clear () {
        this.database = [];
        this.save ();
    }

    add (values) {
        this.database.push(values);
        this.save ();
    }

    replace (values) {
        this.database = values;
    }

}

export default new FormStore();