import React from 'react';
import WindowsStore from '../stores/WindowsStore';
import {observer} from "mobx-react";

const Modal = observer(({content}) => {
    return (
        <div className={`cmodal ${WindowsStore.modalState ? 'cmodal__show' : ''}`}>
            <div className="cmodal-body">
                <div className="cmodal-body__close-btn" onClick={() => WindowsStore.showModal(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M4.293,18.293,10.586,12,4.293,5.707A1,1,0,0,1,5.707,4.293L12,10.586l6.293-6.293a1,1,0,1,1,1.414,1.414L13.414,12l6.293,6.293a1,1,0,1,1-1.414,1.414L12,13.414,5.707,19.707a1,1,0,0,1-1.414-1.414Z"/>
                    </svg>
                </div>
                <div className="cmodal-body__content">
                    {content}
                </div>
            </div>
        </div>
    );
});

export default Modal;
