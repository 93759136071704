import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import './assets/style/app.scss';
import 'react-toastify/dist/ReactToastify.css';
import Manager from "./pages/Manager";

export default function App() {
    return (
      <Router>
          <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/manager" element={<Manager/>} />
          </Routes>
      </Router>
  );
}
