import { makeAutoObservable } from "mobx"

class CommonStore {
    emailSenderTimer = null;


    constructor() {
          makeAutoObservable(this);
    }

    setEmailSenderTimer (val) {
        this.emailSenderTimer = val;
    }
}

export default new CommonStore();