import React, {useState, useEffect, useRef} from 'react';
import {observer} from "mobx-react";
import WindowsStore from "../stores/WindowsStore";
import ConfigStore from "../stores/ConfigStore";

const VideoPlayer = observer(({src, caption = ''}) => {
    const [videoState, setVideoState] = useState(false);
    const videoRef = useRef();
    const modalState = WindowsStore.modalState;

    const videoAction = () => {
        setVideoState(!videoState);
        updateVideoState(videoState);
    }

    const updateVideoState = (state) => {
        return state ? playVideo() : pauseVideo();
    }

    const playVideo = () => {
        const videoObj = videoRef.current;
        const playPromise = videoObj?.play();

        if (playPromise !== undefined) {
            playPromise.then(_ => {
                videoObj?.play();
            })
            .catch(error => {});
        }
    }

    const pauseVideo = () => {
        videoRef.current?.pause();
    }

    useEffect(() => {
        if (src) {
            videoRef.current?.load();
            setVideoState(false);
        }
    }, [src]);

    useEffect(() => {
        if (!modalState) pauseVideo(); else playVideo();
    }, [modalState]);

    return (
        <div className="video-player__wrapper">
            <div className='video-player__container'>
                <video
                    ref={videoRef}
                    className="video-player"
                    preload="auto"
                    autoPlay={true}
                    loop={true}
                    onClick={() => videoAction()}
                >
                    <source src={ConfigStore.baseStoreUrl + src} type="video/mp4"/>
                </video>
                <div className="video-player__caption">{caption}</div>
            </div>
        </div>
    );
});

export default VideoPlayer;
